import revive_payload_client_4sVQNw7RlN from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/mrhs/chronic-pain/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_dw2T9lEw4h from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_izaWKZ8rEu from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import motion_fmZWau4t0U from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_client_LcKgStRyi6 from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import nuxt_plugin_6wEQMY3tee from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import gtag_client_R70iZRv6jK from "/vercel/path0/apps/mrhs/chronic-pain/plugins/gtag.client.js";
import hotjar_client_khHI17zbiJ from "/vercel/path0/apps/mrhs/chronic-pain/plugins/hotjar.client.js";
import defaults_aRl2YLnTcb from "/vercel/path0/apps/mrhs/chronic-pain/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_dw2T9lEw4h,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  motion_fmZWau4t0U,
  plugin_client_LcKgStRyi6,
  nuxt_plugin_6wEQMY3tee,
  gtag_client_R70iZRv6jK,
  hotjar_client_khHI17zbiJ,
  defaults_aRl2YLnTcb
]