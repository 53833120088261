import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBreakpoints, LazyContainer, LazyHeading1, LazyHeading2, LazyHeading4, LazyLead, LazyMuted, LazyNumericCircle, LazySemiBold, LazySmartButton, LazyVideoPlayer, LazyMolBanner, LazyMolTestimonial, LazyOrgDoctors, LazyOrgFooter, LazyOrgHeader, LazyOrgHero, LazyOrgHowItWorks, LazyOrgInsurance, LazyOrgStats, LazyOrgTestimonial, LazyOrgTreatments, LazyOrgVideo, LazyOrgFaq, LazySvgoLogo, LazySvgoLogoMobile, LazySvgoCredentialsAma, LazySvgoCredentialsBrown, LazySvgoCredentialsCornell, LazySvgoCredentialsHarvard, LazySvgoCredentialsMountSinai, LazySvgoCredentialsNorthwell, LazySvgoCredentialsNyu, LazySvgoIconsEpiduralSteroids, LazySvgoIconsFacebook, LazySvgoIconsInstagram, LazySvgoIconsKetamineInfusion, LazySvgoIconsRegenerativeHealth, LazySvgoIconsSelectiveNerveBlock, LazySvgoIconsSpinalCordStimulators, LazySvgoIconsStellateGanglionBlock, LazySvgoIconsTranscranialMagneticStimulation, LazySvgoIconsTriggerPointInjections, LazySvgoIconsX, LazySvgoIconsYoutube, LazySvgoProvidersAetna, LazySvgoProvidersBlueCross, LazySvgoProvidersCigna, LazySvgoProvidersEmpireBlueCross, LazySvgoProvidersHealthFirst, LazySvgoProvidersMedicare, LazySvgoProvidersOxford, LazySvgoProvidersUnited } from '#components'
const lazyGlobalComponents = [
  ["Breakpoints", LazyBreakpoints],
["Container", LazyContainer],
["Heading1", LazyHeading1],
["Heading2", LazyHeading2],
["Heading4", LazyHeading4],
["Lead", LazyLead],
["Muted", LazyMuted],
["NumericCircle", LazyNumericCircle],
["SemiBold", LazySemiBold],
["SmartButton", LazySmartButton],
["VideoPlayer", LazyVideoPlayer],
["MolBanner", LazyMolBanner],
["MolTestimonial", LazyMolTestimonial],
["OrgDoctors", LazyOrgDoctors],
["OrgFooter", LazyOrgFooter],
["OrgHeader", LazyOrgHeader],
["OrgHero", LazyOrgHero],
["OrgHowItWorks", LazyOrgHowItWorks],
["OrgInsurance", LazyOrgInsurance],
["OrgStats", LazyOrgStats],
["OrgTestimonial", LazyOrgTestimonial],
["OrgTreatments", LazyOrgTreatments],
["OrgVideo", LazyOrgVideo],
["OrgFaq", LazyOrgFaq],
["SvgoLogo", LazySvgoLogo],
["SvgoLogoMobile", LazySvgoLogoMobile],
["SvgoCredentialsAma", LazySvgoCredentialsAma],
["SvgoCredentialsBrown", LazySvgoCredentialsBrown],
["SvgoCredentialsCornell", LazySvgoCredentialsCornell],
["SvgoCredentialsHarvard", LazySvgoCredentialsHarvard],
["SvgoCredentialsMountSinai", LazySvgoCredentialsMountSinai],
["SvgoCredentialsNorthwell", LazySvgoCredentialsNorthwell],
["SvgoCredentialsNyu", LazySvgoCredentialsNyu],
["SvgoIconsEpiduralSteroids", LazySvgoIconsEpiduralSteroids],
["SvgoIconsFacebook", LazySvgoIconsFacebook],
["SvgoIconsInstagram", LazySvgoIconsInstagram],
["SvgoIconsKetamineInfusion", LazySvgoIconsKetamineInfusion],
["SvgoIconsRegenerativeHealth", LazySvgoIconsRegenerativeHealth],
["SvgoIconsSelectiveNerveBlock", LazySvgoIconsSelectiveNerveBlock],
["SvgoIconsSpinalCordStimulators", LazySvgoIconsSpinalCordStimulators],
["SvgoIconsStellateGanglionBlock", LazySvgoIconsStellateGanglionBlock],
["SvgoIconsTranscranialMagneticStimulation", LazySvgoIconsTranscranialMagneticStimulation],
["SvgoIconsTriggerPointInjections", LazySvgoIconsTriggerPointInjections],
["SvgoIconsX", LazySvgoIconsX],
["SvgoIconsYoutube", LazySvgoIconsYoutube],
["SvgoProvidersAetna", LazySvgoProvidersAetna],
["SvgoProvidersBlueCross", LazySvgoProvidersBlueCross],
["SvgoProvidersCigna", LazySvgoProvidersCigna],
["SvgoProvidersEmpireBlueCross", LazySvgoProvidersEmpireBlueCross],
["SvgoProvidersHealthFirst", LazySvgoProvidersHealthFirst],
["SvgoProvidersMedicare", LazySvgoProvidersMedicare],
["SvgoProvidersOxford", LazySvgoProvidersOxford],
["SvgoProvidersUnited", LazySvgoProvidersUnited],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
