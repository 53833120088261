<template>
  <section
    class="-mt-16 relative h-screen flex flex-col items-center justify-center text-center text-white py-0 px-3"
  >
    <div
      class="after:content-[''] after:bg-secondary/40 after:z-0 after:absolute after:left-0 after:right-0 after:h-full after:w-full absolute top-0 left-0 w-full h-full overflow-hidden"
    >
      <video
        class="min-w-full min-h-full absolute object-cover top-1/2 left-1/2 transform-gpu -translate-x-1/2 -translate-y-1/2"
        autoplay
        muted
        loop
        playsinline
      >
        <img
          src="/images/pain-relief-poster.jpg"
          class="w-full h-full object-cover"
        />
        <source src="/videos/pain-relief.webm" type="video/webm" />
        <source src="/videos/pain-relief.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="z-10 w-full">
      <div class="container">
        <div class="flex text-left">
          <div class="md:w-2/3 xl:w-1/2 2xl:w-3/6 space-y-3">
            <heading-1>Free Yourself from Anxiety & Stress</heading-1>
            <lead
              >Innovative treatments to clear the path towards your mental
              health from world class doctors.</lead
            >
            <smart-button
              variant="primary"
              size="xl"
              class="sm:w-full md:w-1/2"
            >
              Book an Appointment
            </smart-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>
