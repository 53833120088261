<template>
  <section id="testimonials" class="bg-secondary">
    <container>
      <div
        class="flex flex-row justify-center gap-10 text-lg px-2 py-8 md:p-10"
      >
        <div class="md:w-1/3">
          <img
            class="object-cover h-[310px] w-[530px] rounded-xl"
            src="/images/claudia@2x.png"
          />
          <figure>
            <blockquote class="text-white mt-4">
              <p>
                The team and Dr. Canty were very empathetic and put you right at
                ease. Everyone could not do enough to make it easy for you and
                everything was explained perfectly to make you feel comfortable.
              </p>
            </blockquote>
            <figcaption class="mt-4 text-white">
              <div class="font-semibold">Kelly M, age 29</div>
              <div class="font-light text-white/70">Dental Hygienist</div>
            </figcaption>
          </figure>
        </div>
        <div class="hidden md:block w-1/3">
          <blockquote class="text-white">
            The team and Dr. Canty were very empathetic and put you right at
            ease. Everyone could not do enough to make it easy for you and
            everything was explained perfectly to make you feel comfortable.
          </blockquote>
        </div>
      </div>
    </container>
  </section>
</template>

<script setup></script>
